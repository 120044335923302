<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline ma-0 pa-0"> Zmień status </v-list-item-title>
              <v-list-item-subtitle class="mt-n4">
                Tutaj możesz zmienić status transportu
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-row justify="center" align="center" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-radio-group v-model="status" hide-details dense class="ma-0">
              <v-radio
                v-for="s in availableStatuses"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" class="mt-4">
            <v-checkbox
              v-model="infoCheckbox"
              label="Dodaj informacje o powodzie zmiany statusu"
              color="primary"
              hide-details
              dense
            ></v-checkbox>

            <v-textarea
              v-show="infoCheckbox"
              v-model="info"
              name="info"
              label="Powód zmiany statusu"
              maxlength="255"
              counter="255"
              auto-grow
            />
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-checkbox
              v-model="sendNotification"
              label="Wyślij powiadomienie w aplikacji (dzwonek)"
              color="primary"
              hide-details
              dense
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-checkbox
              v-model="mail"
              label="Wyślij maila z powiadomieniem do klienta"
              color="primary"
              hide-details
              dense
            ></v-checkbox>

            <v-checkbox
              v-show="forceCheckbox"
              v-model="force"
              label="Wymuś akcje (spowoduje to oznaczenie wszystkich paczek jako odebrane)"
              color="primary"
              hide-details
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
          :disabled="status == null"
        >
          Edytuj
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'EditStatus',
    components: {},
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      mode: {
        type: String,
        default: 'delivery',
        validator: val => ['pickup', 'delivery'].includes(val),
      },
      width: {
        type: String,
        default: '620px',
      },
      forceCheckbox: {
        type: Boolean,
        default: false,
      },
      cancelStatus: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      status: null,

      infoCheckbox: false,
      info: '',

      sendNotification: false,

      mail: true,
      force: false,
    }),
    watch: {
      value: function () {
        this.cleanState();
      },
      infoCheckbox: function (value) {
        if (value === false) this.info = '';
      },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
          if (val === false) this.cleanState();
        },
      },
      availableStatuses: function () {
        return [
          {
            id: 0,
            name: 'Wymagana akceptacja',
          },
          {
            id: 1,
            name: 'Oczekuje na magazyn',
          },
          {
            id: 2,
            name: 'Do odbioru u klienta',
          },
          {
            id: 3,
            name: 'Oczekuje na wysyłkę',
          },
        ]
          .concat(
            this.mode === 'delivery'
              ? [
                  {
                    id: 5,
                    name: 'Wysłano',
                  },
                  {
                    id: 6,
                    name: 'Dostarczono',
                  },
                  {
                    id: 7,
                    name: 'Oczekuje na pobranie',
                  },
                  {
                    id: 8,
                    name: 'Zarchiwizowano',
                  },
                ]
              : []
          )
          .concat(
            this.cancelStatus
              ? [
                  {
                    id: 9,
                    name: 'Anulowano',
                  },
                ]
              : []
          );
      },
    },
    methods: {
      cleanState: function () {
        this.status = null;
        this.infoCheckbox = false;
        this.info = '';
        this.sendNotification = false;
        this.mail = true;
        this.force = false;
      },
      submit: function () {
        this.$emit('submit', {
          status: this.status,
          infoCheckbox: this.infoCheckbox,
          info: this.info,
          sendNotification: this.sendNotification,
          mail: this.mail,
          force: this.force,
        });
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style></style>
