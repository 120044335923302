<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col class="pb-0">
        <v-data-table
          v-model="selected"
          :single-select="false"
          :show-select="isAdmin"
          item-key="transport_id"
          :headers="tableHeaders"
          :items="transportsOnPage"
          :height="tableHeight"
          fixed-header
          class="elevation-1 pb-0"
          :loading="loadingTransports"
          loading-text="Ładowanie... Proszę czekać"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 50, 100, 200],
            showCurrentPage: true,
          }"
          :server-items-length="totalTransports"
          ref="my-table"
        >
          <template v-slot:top>
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="12" sm="12" md="2" lg="2" class="my-4">
                <v-toolbar flat>
                  <v-toolbar-title>
                    <p class="font-weight-medium ma-0">Lista dostaw</p>
                    <p class="font-weight-medium ma-0">Dostawy</p>
                  </v-toolbar-title>
                </v-toolbar>
              </v-col>

              <v-col cols="12" sm="12" md="2" lg="2" class="pa-2 my-2">
                <v-select
                  v-model="searchStatus"
                  :items="availableStatuses"
                  label="Status"
                  hide-details
                  item-text="name"
                  item-value="value"
                  dense
                ></v-select>
              </v-col>

              <v-col
                v-if="isAdmin"
                cols="12"
                sm="12"
                md="3"
                lg="3"
                class="pa-2 my-2"
                v-show="selected.length"
              >
                <v-row no-gutters align="center" justify="center">
                  <v-btn color="primary" dark class="mx-1 px-1" @click="statusDialog">
                    Status
                  </v-btn>

                  <v-btn color="primary" dark class="mx-1 px-1" @click="deliveryDialog">
                    Dostawa
                  </v-btn>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4" class="pa-2 my-2">
                <v-text-field
                  v-model="searchString"
                  :label="searchLabel"
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox v-bind="props" v-on="on" :ripple="false"></v-simple-checkbox>
            <span v-if="!!selected.length" class="float-right">
              {{ selected.length }}
            </span>
          </template>

          <template v-slot:[`item.t_number`]="{ item }">
            {{ item.t_number }}
            <v-icon v-if="item.streamsoft_transport_id" small> mdi-check-circle-outline </v-icon>
          </template>

          <template v-slot:[`item.user_id`]="{ item }">
            <a :href="'/uzytkownicy/' + item.user_id">
              {{ item.users_customers.name + ' ' + item.users_customers.surname }}
            </a>
          </template>

          <template v-slot:[`item.sender_id`]="{ item }">
            {{
              item.transports_senders
                ? `${item.transports_senders.postcode} ${item.transports_senders.city}`
                : '???'
            }}
          </template>

          <template v-slot:[`item.receiver_id`]="{ item }">
            {{
              item.transports_receivers
                ? `${item.transports_receivers.postcode} ${item.transports_receivers.city}`
                : '???'
            }}
          </template>

          <template v-slot:[`item.weight`]="{ item }">
            {{ `${item.weight}\xa0kg` }}
          </template>

          <template v-slot:[`item.date_expected`]="{ item }">
            <div class="text-no-wrap">
              {{ item.date_expected }}
              <v-tooltip top v-if="!item.date_expected_confirm && item.date_expected">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isAdmin"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    x-small
                    icon
                    class="ml-n1 mr-1"
                    @click="confirmDate(item)"
                  >
                    <v-icon> mdi-exclamation-thick </v-icon>
                  </v-btn>
                </template>
                <span> Termin <b>NIE</b> uzgodniony z odbiorcą </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.priceVat`]="{ item }">
            {{
              (
                parseFloat(item.priceVat) + parseFloat(item.pickup_price ? item.pickup_price : 0)
              ).toFixed(2) + '\xa0zł'
            }}
          </template>

          <template v-slot:[`item.money_to_collect`]="{ item }">
            {{ `${item.money_to_collect}\xa0zł` }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getStatusColor(item.status)" dark>
              {{ getStatusName(item.status) }}
            </v-chip>
          </template>

          <template v-slot:[`item.transport_id`]="{ item }">
            <v-tab :to="'/transport/' + item.transport_id"> Zobacz </v-tab>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- edit status dialog -->
    <EditStatus
      v-if="isAdmin"
      v-model="editStatusDialog"
      :forceCheckbox="forceCheckbox"
      mode="delivery"
      :cancelStatus="cancelStatus"
      @input="forceCheckbox = false"
      @submit="updateStatus"
    ></EditStatus>

    <!-- new delivery date-picker -->
    <DatePicker
      v-if="isAdmin"
      v-model="editDateDialog"
      title="Edytuj date dostawy"
      :dateOnly="true"
      @save="updateDate($event)"
    ></DatePicker>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- loading data dialog -->
    <ProgressCircularDialog v-model="loadingScreenDialog"></ProgressCircularDialog>
  </v-container>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';

  import InfoSnackBar from '../../components/InfoSnackBar.vue';
  import ProgressCircularDialog from '../../components/ProgressCircularDialog.vue';
  import ConfirmDialog from '../../components/Confirm.vue';
  import DatePicker from '../../components/DatePicker.vue';

  import EditStatus from '../../components/EditStatus.vue';

  export default {
    name: 'PickUpList',
    props: {},
    components: {
      InfoSnackBar,
      ProgressCircularDialog,
      ConfirmDialog,

      EditStatus,
      DatePicker,
    },
    data: () => ({
      info: null,
      loadingScreenDialog: false,

      editStatusDialog: false,
      forceCheckbox: false,

      editDateDialog: false,

      searchString: '',
      searchStatus: null,
      availableStatuses: [
        {
          name: 'Wszystkie',
          value: null,
        },
        {
          name: 'Wymagana akceptacja',
          value: 0,
        },
        {
          name: 'Oczekuje na wysyłkę',
          value: 3,
        },

        {
          name: 'Termin uzgodniony z odbiorcą',
          value: 4,
        },
        {
          name: 'Wysłano',
          value: 5,
        },
        {
          name: 'Dostarczono',
          value: 6,
        },
        {
          name: 'Oczekuje na pobranie',
          value: 7,
        },
        {
          name: 'Zarchiwizowano',
          value: 8,
        },
        {
          name: 'Anulowano',
          value: 9,
        },
      ],

      loadingTransports: true,
      transports: [],
      totalTransports: 0,
      options: {
        page: 1,
        itemsPerPage: 100,
        sortBy: ['t_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      selected: [],
    }),
    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      tableHeight: function () {
        return window.innerHeight * 0.7;
      },
      searchLabel: function () {
        return this.isAdmin
          ? 'kod pocztowy, miejscowość, klient lub numer własny'
          : 'kod pocztowy, miejscowość lub numer własny';
      },
      tableHeaders: function () {
        return [
          {
            text: 'Numer transportu',
            align: 'left',
            value: 't_number',
          },
        ]
          .concat(
            this.isAdmin
              ? [
                  {
                    text: 'Klient',
                    value: 'user_id',
                    sortable: false,
                  },
                ]
              : [
                  {
                    text: 'Klient',
                    value: 'r_name',
                    sortable: false,
                  },
                ]
          )
          .concat([
            {
              text: 'Numer własny',
              value: 'extension',
            },
            {
              text: 'Dostarczyć',
              value: 'receiver_id',
              sortable: false,
            },
          ])
          .concat(
            this.isAdmin
              ? [
                  {
                    text: 'Waga',
                    value: 'weight',
                    sortable: false,
                  },
                ]
              : []
          )
          .concat([
            {
              text: 'Transport',
              value: 'content',
              sortable: false,
            },
            {
              text: 'Przewidywana dostawa',
              value: 'date_expected',
            },
            {
              text: 'Kwota brutto',
              value: 'priceVat',
              sortable: false,
            },
            {
              text: 'Kwota pobrania',
              value: 'money_to_collect',
              sortable: false,
            },
            {
              text: 'Status',
              value: 'status',
              align: 'center',
              sortable: false,
            },
            {
              text: 'Szczegóły',
              value: 'transport_id',
              sortable: false,
            },
          ]);
      },
      cancelStatus: function () {
        return this.selected.length === 1 && this.selected[0].status <= 2;
      },
      transportsOnPage: function () {
        return this.transports.slice(
          (this.options.page - 1) * this.options.itemsPerPage,
          this.options.page * this.options.itemsPerPage
        );
      },
    },
    watch: {
      searchString: function (str) {
        const that = this;
        setTimeout(function () {
          if (that.searchString === (' ' + str).slice(1)) that.resetTransports();
        }, 700);
      },
      searchStatus: function () {
        this.resetTransports();
      },
      'options.sortDesc': function (next, before) {
        if (next != before) this.resetTransports();
      },
      // it will only allow to select from one page
      // 'options.page': function (next, before) {
      //   if (next != before) this.selected = [];
      // },
      // 'options.itemsPerPage': function (next, before) {
      //   if (next != before) this.selected = [];
      // },
      'options.page': async function () {
        const table = this.$refs['my-table'];
        const wrapper = table.$el.querySelector('div.v-data-table__wrapper');

        this.$vuetify.goTo(table); // to table
        this.$vuetify.goTo(table, { container: wrapper }); // to header
      },
    },
    methods: {
      getStatusColor: function (status) {
        // STATUS:
        switch (status) {
          case 0:
            return 'red';
          case 1:
            return 'black';
          case 2:
            return 'grey';
          case 3:
            return 'teal';
          case 4:
            return 'green';
          case 5:
            return 'amber';
          case 6:
            return 'blue';
          case 7:
            return 'indigo';
          case 8:
            return 'silver';
          case 9:
            return 'red darken-4';
        }
      },
      getStatusName: function (status) {
        // STATUS:
        switch (status) {
          case 0:
            return 'Wymagana akceptacja';
          case 1:
            return 'Oczekuje na magazyn';
          case 2:
            return 'Do odbioru u klienta';
          case 3:
            return 'Oczekuje na wysyłkę';
          case 4:
            return 'Termin uzgodniony z odbiorcą';
          case 5:
            return 'Wysłano';
          case 6:
            return 'Dostarczono';
          case 7:
            return 'Oczekuje na pobranie';
          case 8:
            return 'Zarchiwizowano';
          case 9:
            return 'Anulowano';
        }
      },

      confirmDate: async function (transport) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Potwierdzenie daty',
            'Czy data jest ustalona z klientem?',
            {
              color: 'black',
              cancelButtonText: 'Nie',
              acceptButtonText: 'Tak',
            }
          ))
        )
          return;

        this.loadingScreenDialog = true;

        const result = await axios({
          url: this.$store.state.url + '/api/transport/modify/confirmDate',
          data: {
            token: this.$store.state.token,
            transport_id: transport?.transport_id,
            type: 'delivery',
          },
          method: 'POST',
        }).catch(() => {
          // console.error(err);
          this.info = {
            message: 'UPDATE CONFIRM DATE ERROR',
            status: 'error',
          };
        });

        if (result.data?.status == 200) transport.date_expected_confirm = 1;
        else
          this.info = {
            message: result.data?.message || '',
            status: 'error',
          };

        this.loadingScreenDialog = false;
      },
      statusDialog: function () {
        this.editStatusDialog = true;
      },
      updateStatus: async function (obj) {
        const {
          force = false,
          info = '',
          infoCheckbox = false,
          sendNotification = false,
          status,
        } = obj;

        if (!this.selected.length)
          return (this.info = {
            message: 'Nie wybrano żadnego transportu',
          });

        // STATUS: if cancle please confirm action
        if (
          status == 9 &&
          !(await this.$refs.confirmDialog.open(
            'Zmiana statusu',
            'Czy na pewno chcesz anulować transport?',
            { color: 'red' }
          ))
        )
          return;

        this.loadingScreenDialog = true;

        const result = await axios({
          url: this.$store.state.url + '/api/transport/modify/status',
          data: {
            token: this.$store.state.token,
            id: this.selected.map(s => s.transport_id),
            status: status,
            force: force,
            message: info,
            mail: infoCheckbox,
            notification: sendNotification,
          },
          method: 'POST',
        }).catch(() => {
          // console.error(err);
          this.info = {
            message: 'UPDATE STATUS ERROR',
            status: 'error',
          };
        });

        if (result.data.status !== 200) {
          if (result.data?.status === 403) this.forceCheckbox = true;

          this.info = {
            message: result.data.message,
            status: 'error',
          };
        } else {
          // update status
          if (
            (status >= 8 && this.searchStatus < 8) ||
            (status != this.searchStatus && this.searchStatus >= 8) ||
            status == 2
          ) {
            const selectedIDs = this.selected.map(s => s.transport_id);
            this.transports = this.transports.filter(
              elem => selectedIDs.indexOf(elem.transport_id) === -1
            );
            this.selected = [];
          } else this.selected.forEach(elem => (elem.status = status));

          this.info = {
            message: result.data.message,
            status: 'success',
          };
          this.forceCheckbox = false;
          this.editStatusDialog = false;
        }

        this.loadingScreenDialog = false;
      },
      deliveryDialog: function () {
        this.editDateDialog = true;
      },
      updateDate: async function (obj) {
        this.loadingProgress = true;
        const result = await axios({
          url: this.$store.state.url + '/api/transport/modify/date',
          data: {
            token: this.$store.state.token,
            id: this.selected.map(elem => elem.transport_id),
            date: moment(obj.date).toDate(), // moment(obj.date).format('DD.MM.YYYY'),
            type: 'delivery',
            mail: obj.checkboxes.sendMail,
            confirm_date: obj.checkboxes.confirmDate,
            notification: obj.checkboxes.sendNotification,
            info: {
              delivery: {
                start: obj.timeStart,
                end: obj.timeEnd,
              },
            },
          },
          method: 'POST',
        }).catch(() => {
          // console.error(err);
          this.info = {
            message: 'UPDATE DATE ERROR',
            status: 'error',
          };
        });

        if (result.data.status == 200)
          for (const transport of this.selected) {
            transport.date_expected = moment(obj.date).format('DD.MM.YYYY');
            transport.date_expected_confirm = obj.checkboxes.confirmDate;
          }
        else
          this.info = {
            message: result.data?.message || '',
            status: 'error',
          };

        this.loadingProgress = false;
      },

      resetTransports: async function () {
        const { transports, total } = await this.getTransports();

        this.selected = [];
        this.options.page = 1;
        this.transports = transports;
        this.totalTransports = total;
      },

      async getTransports(from = null, to = null) {
        this.loadingScreenDialog = true;
        this.loadingTransports = true;
        const results = await axios({
          url: this.$store.state.url + '/api/transport/searchV2',
          data: {
            token: this.$store.state.token,
            search: this.searchString,
            status: this.searchStatus,
            sortBy: this.options.sortBy.reduce((acc, elem, index) => {
              acc[elem] = this.options.sortDesc[index] ? 'DESC' : 'ASC';
              return acc;
            }, {}),
            by_user: this.$route.params.id ? decodeURIComponent(this.$route.params.id) : null,
            date_from: null,
            date_to: null,
            mode: 'delivery',
            from: from,
            to: to,
          },
          method: 'POST',
        }).catch(() => {
          // console.error(err);
          this.info = {
            message: 'GET TRANSPORT ERROR',
            status: 'error',
          };
        });

        this.loadingScreenDialog = false;
        this.loadingTransports = false;

        return {
          transports:
            results?.data?.transports.map(elem => {
              const pck = JSON.parse(elem.packages) || [];
              return Object.assign(elem, {
                packages: pck,
                date_created: moment(elem.date_created).format('DD.MM.YYYY'),
                date_expected: elem.date_expected
                  ? moment(elem.date_expected).format('DD.MM.YYYY')
                  : '',
                pickup_date: elem.pickup_date ? moment(elem.pickup_date).format('DD.MM.YYYY') : '',
                weight: pck
                  .reduce(
                    (acc, p) =>
                      acc +
                      (p.quantity ? parseInt(p.quantity) : 1) *
                        (p.packages
                          ? p.packages.reduce((acc, elem) => acc + parseFloat(elem.weight), 0)
                          : p.weight || 0),
                    0
                  )
                  .toFixed(2),
              });
            }) || [],
          total: results?.data?.total || 0,
        };
      },
    },
    mounted() {},
    created() {
      this.resetTransports();
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
  .text-start {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
</style>
