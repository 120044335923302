var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-0"},[_c('v-data-table',{ref:"my-table",staticClass:"elevation-1 pb-0",attrs:{"single-select":false,"show-select":_vm.isAdmin,"item-key":"transport_id","headers":_vm.tableHeaders,"items":_vm.transportsOnPage,"height":_vm.tableHeight,"fixed-header":"","loading":_vm.loadingTransports,"loading-text":"Ładowanie... Proszę czekać","options":_vm.options,"footer-props":{
          itemsPerPageOptions: [5, 10, 20, 50, 100, 200],
          showCurrentPage: true,
        },"server-items-length":_vm.totalTransports},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"my-4",attrs:{"cols":"12","sm":"12","md":"2","lg":"2"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('p',{staticClass:"font-weight-medium ma-0"},[_vm._v("Lista dostaw")]),_c('p',{staticClass:"font-weight-medium ma-0"},[_vm._v("Dostawy")])])],1)],1),_c('v-col',{staticClass:"pa-2 my-2",attrs:{"cols":"12","sm":"12","md":"2","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.availableStatuses,"label":"Status","hide-details":"","item-text":"name","item-value":"value","dense":""},model:{value:(_vm.searchStatus),callback:function ($$v) {_vm.searchStatus=$$v},expression:"searchStatus"}})],1),(_vm.isAdmin)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],staticClass:"pa-2 my-2",attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mx-1 px-1",attrs:{"color":"primary","dark":""},on:{"click":_vm.statusDialog}},[_vm._v(" Status ")]),_c('v-btn',{staticClass:"mx-1 px-1",attrs:{"color":"primary","dark":""},on:{"click":_vm.deliveryDialog}},[_vm._v(" Dostawa ")])],1)],1):_vm._e(),_c('v-col',{staticClass:"pa-2 my-2",attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":_vm.searchLabel,"single-line":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)],1)]},proxy:true},{key:"header.data-table-select",fn:function(ref){
        var on = ref.on;
        var props = ref.props;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"ripple":false}},'v-simple-checkbox',props,false),on)),(!!_vm.selected.length)?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.selected.length)+" ")]):_vm._e()]}},{key:"item.t_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.t_number)+" "),(item.streamsoft_transport_id)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e()]}},{key:"item.user_id",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":'/uzytkownicy/' + item.user_id}},[_vm._v(" "+_vm._s(item.users_customers.name + ' ' + item.users_customers.surname)+" ")])]}},{key:"item.sender_id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.transports_senders ? ((item.transports_senders.postcode) + " " + (item.transports_senders.city)) : '???')+" ")]}},{key:"item.receiver_id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.transports_receivers ? ((item.transports_receivers.postcode) + " " + (item.transports_receivers.city)) : '???')+" ")]}},{key:"item.weight",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.weight) + " kg"))+" ")]}},{key:"item.date_expected",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.date_expected)+" "),(!item.date_expected_confirm && item.date_expected)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.isAdmin)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-n1 mr-1",attrs:{"color":"error","x-small":"","icon":""},on:{"click":function($event){return _vm.confirmDate(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-exclamation-thick ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" Termin "),_c('b',[_vm._v("NIE")]),_vm._v(" uzgodniony z odbiorcą ")])]):_vm._e()],1)]}},{key:"item.priceVat",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(( parseFloat(item.priceVat) + parseFloat(item.pickup_price ? item.pickup_price : 0) ).toFixed(2) + '\xa0zł')+" ")]}},{key:"item.money_to_collect",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.money_to_collect) + " zł"))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}},{key:"item.transport_id",fn:function(ref){
        var item = ref.item;
return [_c('v-tab',{attrs:{"to":'/transport/' + item.transport_id}},[_vm._v(" Zobacz ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),(_vm.isAdmin)?_c('EditStatus',{attrs:{"forceCheckbox":_vm.forceCheckbox,"mode":"delivery","cancelStatus":_vm.cancelStatus},on:{"input":function($event){_vm.forceCheckbox = false},"submit":_vm.updateStatus},model:{value:(_vm.editStatusDialog),callback:function ($$v) {_vm.editStatusDialog=$$v},expression:"editStatusDialog"}}):_vm._e(),(_vm.isAdmin)?_c('DatePicker',{attrs:{"title":"Edytuj date dostawy","dateOnly":true},on:{"save":function($event){return _vm.updateDate($event)}},model:{value:(_vm.editDateDialog),callback:function ($$v) {_vm.editDateDialog=$$v},expression:"editDateDialog"}}):_vm._e(),_c('ConfirmDialog',{ref:"confirmDialog"}),_c('InfoSnackBar',{model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}}),_c('ProgressCircularDialog',{model:{value:(_vm.loadingScreenDialog),callback:function ($$v) {_vm.loadingScreenDialog=$$v},expression:"loadingScreenDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }