<template>
  <v-dialog v-model="isActive" fullscreen persistent>
    <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
      <v-layout justify-center align-center>
        <v-progress-circular indeterminate color="amber"> </v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ProgressCircularDialog',
    props: {
      value: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({}),
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
  };
</script>

<style>
</style>
